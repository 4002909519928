import React, { ReactElement, useCallback, useContext, useEffect, useState, Fragment } from 'react';
import { CheckCircleIcon, EmojiSadIcon, LightBulbIcon, XCircleIcon } from '@heroicons/react/solid';
import { useHistory, useParams } from 'react-router-dom';
import { get, post, put } from '../../helper/fetch';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
import { DrawerContext } from '../../context/DrawerContext';
import { toast } from 'react-toastify';
import { ChatInputWrapper, CW } from '../BookingDetailForm/BookingDetailsForm.style';
import Button from '../../components/Button/Button';
import { Booking } from '../../settings/bookingTypes';
import Comments from '../../components/Comments/Comments';
import { Dialog, Transition } from "@headlessui/react";
import BookingOffersViewGrid from "../BookingOffersViewGrid/BookingOffersViewGrid";
import AvailabilitySearcher from "../AvailabilitySearcher/AvailabilitySearcher";

const getStatus = (status): ReactElement => {
  switch (status) {
    case 'accepted': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <CheckCircleIcon
            className="flex-shrink-0 mr-1.5 h-8 w-8 text-green-400"
            aria-hidden="true"
          />
        </p>
      );
      break;
    }
    case 'requested': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <LightBulbIcon
            className="flex-shrink-0 mr-1.5 h-8 w-8 text-yellow-400"
            aria-hidden="true"
          />
        </p>
      );
      break;
    }
    case 'rejected': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <XCircleIcon
            className="flex-shrink-0 mr-1.5 h-8 w-8 text-red-400"
            aria-hidden="true"
          />
        </p>
      );
      break;
    }
    case 'cancelled':
    case 'canceled': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <EmojiSadIcon
            className="flex-shrink-0 mr-1.5 h-8 w-8 text-red-400"
            aria-hidden="true"
          />
        </p>
      );
    }
    case 'confirmed':
    case 'completed': {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">
          <CheckCircleIcon
            className="flex-shrink-0 mr-2.5 h-8 w-8 text-green-400"
            aria-hidden="true"
          />
        </p>
      );
      break;
    }
    default: {
      return (
        <p className="mt-2 flex items-center text-sm text-gray-500">???</p>
      );
      break;
    }
  }
};

type Section =
  | 'RECEIPT'
  | 'EXTRAS'
  | 'CLIENT'
  | 'CLEANER'
  | 'DATE_TIME'
  | 'STATUS'
  | 'PROPERTY';

type GroupBooking = {
  id: number;
  status: string;
  booking_date: string;
  created_at: string;
  details: {
    bookingIds: number[];
  };
};

export default function SingleBookingView() {
  const { id } = useParams<{ id: string }>();
  const [booking, setBooking] = useState({} as Booking);
  const [error, setError] = useState(null);
  const history = useHistory();
  const [messageLoading, setMessageLoading] = useState(false);
  const [messagesList, setMessagesList] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [groupBookings, setGroupBookings] = useState<GroupBooking | null>(null);
  const { state, dispatch } = useContext(DrawerContext);
  const [notes, setNotes] = useState([]);
  const [receiptHistory, setReceiptHistory] = useState([]);
  const [viewReceiptHistory, setViewReceiptHistory] = useState(false);
  const [isOfferDialogOpen, setIsOfferDialogOpen] = useState(false);
  const [isOffersGridDialogOpen, setIsOffersGridDialogOpen] = useState(false);
  const [offers, setOffers] = useState([]);
  const closeDrawer = useCallback(
    () => dispatch({ type: 'CLOSE_DRAWER' }),
    [dispatch]
  );

  const constUpdateTextBoxmsg = (e: any) => {
    setNewMessage(e.target.value);
  };

  async function loadBookingData(id) {
    try {
      const b = await get(`/bookings/${id}`);
      setBooking(b);
      fetchConvo(b.conversation_id);
      fetchNotes(b.id);
      fetchGroupBookings(b.group_booking_id);
      fetchReceiptHistory(b.id);
      fetchOffers(b.id);
    } catch (e) {
      setError('could not fetch booking ' + id);
    }
  }

  async function fetchConvo(conversionId) {
    try {
      const msgs = await get(`/bookings/conversation/${conversionId}`);
      setMessagesList(msgs);
    } catch (e) {
      console.log('could not fetch conversation', e);
    }
  }

  async function fetchNotes(bookingId) {
    try {
      const notes = await get(`/bookings/${bookingId}/notes`);
      setNotes(notes || []);
    } catch (e) {
      console.log('could not fetch notes', e);
    }
  }

  async function fetchGroupBookings(groupBookingId) {
    if (!groupBookingId) return;
    try {
      const groupBookings = await get(`/bookings/group/${groupBookingId}`);
      setGroupBookings(groupBookings);
    } catch (e) {
      console.log('could not fetch group bookings', e);
    }
  }

  async function fetchReceiptHistory(bookingId) {
    try {
      const receiptHistory = await get(`/bookings/${bookingId}/receipt/history`);
      setReceiptHistory(receiptHistory);
    } catch (e) {
      console.log('could not fetch receipt history', e);
    }
  }

  useEffect(() => {
    setError(null);
    const fetchData = async () => await loadBookingData(id);
    if (id) {
      fetchData();
    }
  }, []);

  const fetchOffers = async (bookingId) => {
    try {
      const response = await get(`/cleanerlistings/${bookingId}/offers`);
      setOffers(response.offers);
    } catch (error) {
      console.error("Error fetching offers:", error);
    }
  };

  useEffect(() => {
    if (booking.id) {
      fetchOffers(booking.id);
    }
  }, [booking.id]);

  const handleDeleteOffer = async () => {
    await fetchOffers(booking.id);
  };

  const onOfferSent = async () => {
    await fetchOffers(booking.id);
    setIsOfferDialogOpen(false);
  };

  async function updateSection(bookingId, sectionType: Section, data) {
    if (sectionType === 'RECEIPT') {
      const response = await put(`/bookings/${bookingId}/receipt`, {
        receipt: data.receipt
      });
      if (response.status === 200) {
        await loadBookingData(bookingId);
        toast.success('Receipt updated');
      } else {
        toast.error('Could not update receipt');
      }
      if (data.extras) {
        await updateSection(
          bookingId,
          'EXTRAS',
          data.extras.map((e) => e.id)
        );
      }
    }

    if (sectionType === 'CLEANER') {
      const response = await put(`/bookings/${bookingId}/cleaners`, data);
      if (response.status === 200) {
        await loadBookingData(bookingId);
        toast.success('Cleaning transfered!');
      } else {
        toast.error('Could not transfer cleaning');
      }
    }
    if (sectionType === 'CLIENT') {
      const response = await put(`/bookings/${bookingId}/clients`, data);
      if (response.status === 200) {
        await loadBookingData(bookingId);
        toast.success('Cleaning transfered!');
      } else {
        toast.error('Could not transfer cleaning');
      }
    }
    if (sectionType === 'PROPERTY') {
      const response = await put(`/bookings/${bookingId}/property`, data);
      if (response.status === 200) {
        await loadBookingData(bookingId);
        toast.success('Cleaning property updated');
      } else {
        toast.error('Could not update cleaning property');
      }
    }
    if (sectionType === 'DATE_TIME') {
      const response = await put(`/bookings/${bookingId}/datetime`, data);
      if (response.status === 200) {
        await loadBookingData(bookingId);
        toast.success('Date and time updated!');
      } else {
        toast.error('Could not update date and time');
      }
    }
    if (sectionType === 'STATUS') {
      const response = await put(`/bookings/${bookingId}/status`, data);
      if (response.status === 200) {
        await loadBookingData(bookingId);
        toast.success('status updated!');
      } else {
        toast.error('Could not update status');
      }
    }
    if (sectionType === 'EXTRAS') {
      const response = await put(`/bookings/${bookingId}/extras`, data);
      if (response.status === 200) {
        await loadBookingData(bookingId);
        toast.success('extras updated!');
      } else {
        toast.error('Could not update extras');
      }
    }
    closeDrawer();
  }

  function showReceiptHistory() {

  }

  async function openEditSection(sectionType: Section, data: any) {
    if (sectionType === 'RECEIPT') {
      dispatch({
        type: 'OPEN_DRAWER',
        drawerComponent: 'EDIT_BOOKING_RECEIPT',
        data: {
          id: booking.id,
          receipt: data,
          extras: booking.extras,
          onSubmit: (data) => updateSection(booking.id, sectionType, data)
        }
      });
    }
    if (sectionType === 'EXTRAS') {
      dispatch({
        type: 'OPEN_DRAWER',
        drawerComponent: 'EDIT_BOOKING_EXTRAS',
        data: {
          id: booking.id,
          initialExtras: booking.extras.map((e) => e.id),
          onSubmit: (data) => updateSection(booking.id, sectionType, data)
        }
      });
    }
    if (sectionType === 'CLEANER') {
      //TODO: fetch cleaner
      try {
        const cleaner = data ? await get(`/cleaners/${data}`) : null;
        toast.success('Fetched cleaner');
        dispatch({
          type: 'OPEN_DRAWER',
          drawerComponent: 'EDIT_BOOKING_CLEANER',
          data: {
            id: booking.id,
            cleaner: cleaner,
            onSubmit: (data) => updateSection(booking.id, sectionType, data)
          }
        });
      } catch (e) {
        toast.error('Could not fetch cleaner');
        return;
      }
    }
    if (sectionType === 'CLIENT') {
      const client = data ? await get(`/clients/${data}`) : null;
      dispatch({
        type: 'OPEN_DRAWER',
        drawerComponent: 'EDIT_BOOKING_CLIENT',
        data: {
          id: booking.id,
          client: client,
          onSubmit: (data) => updateSection(booking.id, sectionType, data)
        }
      });
    }
    if (sectionType === 'PROPERTY') {
      const client = data ? await get(`/clients/${data}`) : null;
      dispatch({
        type: 'OPEN_DRAWER',
        drawerComponent: 'EDIT_BOOKING_PROPERTY',
        data: {
          id: booking.id,
          client: client,
          onSubmit: (data) => updateSection(booking.id, sectionType, data)
        }
      });
    }
    if (sectionType === 'DATE_TIME') {
      dispatch({
        type: 'OPEN_DRAWER',
        drawerComponent: 'EDIT_BOOKING_DATE_TIME',
        data: {
          id: booking.id,
          dateTime: {
            start_hour: booking.start_hour,
            start_minute: booking.start_minute,
            end_hour: booking.end_hour,
            end_minute: booking.end_minute,
            booking_date: booking.booking_date,
            date_override: booking.date_override
          },
          onSubmit: (data) => updateSection(booking.id, sectionType, data)
        }
      });
    }
    if (sectionType === 'STATUS') {
      dispatch({
        type: 'OPEN_DRAWER',
        drawerComponent: 'EDIT_BOOKING_STATUS',
        data: {
          id: booking.id,
          status: booking.status,
          recurrance: booking.recurrance,
          onSubmit: (data) => updateSection(booking.id, sectionType, data)
        }
      });
    }
  }

  const submitNewMessage = async (newMessage, conversationId) => {
    try {
      const res = await post('/bookings/conversations/' + conversationId, {
        newMessage: newMessage
      });
      if (res.status !== 201) {
        toast.error('Could not add message');
        return false;
      }
      toast.success('Added message');
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  const submitNewMessageToBooking = async (newMessage, conversationId) => {
    //TODO: nearly guaranteed to be total garbage. Need to use reducer instead of useState
    setMessageLoading(true);
    setNewMessage('');
    try {
      const res = await submitNewMessage(newMessage, conversationId);
      if (res) {
        const msg = {
          createdAt: new Date(),
          sender: {
            role: 'client',
            name: ''
          },
          content: newMessage
        };
        setMessagesList((messagesList) => {
          return [...messagesList, msg];
        });
      }
    } catch (e) {
      //TODO: error msg please
    }
    setMessageLoading(false);
  };

  async function cloneBookingAction(id: number) {
    try {
      const res = await get(`/bookings/${id}/clone`);
      if (!res.bookingId) {
        toast.error('Could not clone booking');
        return false;
      }
      toast.success('Cloned booking!');
      window.open(`/bookings/${res.bookingId}`, '_blank');
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async function offerToCleanersAction(id: number) {
    setIsOfferDialogOpen(true);
  }


  return (
    <div className="relative min-h-screen bg-gray-100">
      {/* Dialog for Offer to Cleaners */}
      <Dialog
        open={isOfferDialogOpen}
        onClose={() => setIsOfferDialogOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-3xl rounded bg-white p-6">
            <Dialog.Title className="text-lg font-medium mb-4">Offer to Cleaners</Dialog.Title>
            <AvailabilitySearcher
              date={booking.booking_date}
              address={booking.bookingProperty?.address}
              bookingId={booking.id}
              onOfferSent={onOfferSent}
            />
            <div className="mt-4 flex justify-start">
              <button
                onClick={() => setIsOfferDialogOpen(false)}
                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              >
                Close
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      {/* Dialog for Show Offers */}
      <Transition appear show={isOffersGridDialogOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsOffersGridDialogOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 mb-4"
                  >
                    Booking Offers
                  </Dialog.Title>
                  <BookingOffersViewGrid
                    offers={offers}
                    onOfferDeleted={handleDeleteOffer}
                  />
                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => setIsOffersGridDialogOpen(false)}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <main className="py-10">
        <div
          className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
          <div className="flex items-center space-x-5">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">
                {booking?.id} {booking.recurrance},{' '}
                {booking.meta?.source
                  ? `source: (${booking.meta?.source})`
                  : ''}
              </h1>
              {booking?.group_booking_id && (
                <div>
                  <p className="text-sm font-medium text-gray-500">
                    Part of #{booking?.group_booking_id} group booking
                  </p>
                  <div
                    className="flex flex-row">{groupBookings && groupBookings.details && groupBookings.details?.bookingIds?.length > 0 && (
                    groupBookings.details?.bookingIds?.map((b) => {
                      return <p className="text-sm font-medium text-gray-500 hover:cursor-pointer underline mr-2"
                                onClick={() => window.open(`/bookings/${b}`)}
                                key={b}
                      >
                        #{b}
                      </p>;
                    })
                  )}
                  </div>
                </div>
              )}

              <div className="flex items-center space-x-5">
                {getStatus(booking.status)}
                <h2>{booking.status}</h2>
                <p
                  className="ml-4 text-sm font-medium text-blue-900 cursor-pointer hover:text-blue-400"
                  onClick={(e) => {
                    openEditSection('STATUS', booking.cleaner);
                  }}
                >
                  Edit
                </p>
              </div>

              <div className="flex items-center space-x-5">
                <p className="text-sm font-medium text-gray-500">
                  Date{' '}
                  <time dateTime="2020-08-25">
                    {dayjs(booking?.booking_date).format('YYYY-MMM-DD')},{' '}
                    {booking?.start_hour}:
                    {booking?.start_minute === 0 ? '00' : booking?.start_minute}{' '}
                    - {booking?.end_hour}:
                    {booking?.end_minute === 0 ? '00' : booking?.end_minute}
                  </time>
                </p>
                {booking?.date_override && (
                  <p className={'text-sm font-medium text-gray-500'}>
                    One Time override Date {booking?.date_override}
                  </p>
                )}
                <p
                  className="ml-4 text-sm font-medium text-blue-900 cursor-pointer hover:text-blue-400"
                  onClick={(e) => {
                    openEditSection('DATE_TIME', booking.cleaner);
                  }}
                >
                  Edit
                </p>
              </div>
              <div className="flex flex-column items-center space-x-5 py-4">
                <button type="button"
                        className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-hsgreen hover:bg-hsgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hsgreen"
                        onClick={() => cloneBookingAction(booking.id)}
                >
                  Clone
                </button>
                <button
                  type="button"
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                  onClick={() => setIsOfferDialogOpen(true)}
                >
                  Offer to Cleaners
                </button>
                {offers.length > 0 && (
                  <button
                    type="button"
                    className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={() => setIsOffersGridDialogOpen(true)}
                  >
                    Show Offers
                  </button>
                )}
                <p>{booking?.cloned_from ? `(cloned from ${booking?.cloned_from})` : ''}</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            {/* Description list*/}
            <section aria-labelledby="applicant-information-title">
              <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <div className="flex flex-row">
                    <p
                      className="max-w-2xl text-lg text-gray-900 cursor-pointer hover:underline"
                      onClick={() =>
                        history.push(`/cleaners/${booking?.listing_id}`)
                      }
                    >
                      Cleaner: {booking?.bookingCleaner?.given_name}{' '}
                      {booking?.bookingCleaner?.family_name}
                    </p>
                    <p
                      className="ml-4 text-sm font-medium text-blue-900 cursor-pointer hover:text-blue-400"
                      onClick={(e) => {
                        openEditSection('CLEANER', booking.cleaner);
                      }}
                    >
                      Edit
                    </p>
                  </div>

                  <div className="flex flex-row">
                    <p
                      className="mt-1 max-w-2xl text-lg text-gray-900 cursor-pointer hover:underline"
                      onClick={() =>
                        history.push(`/clients/${booking?.bookingClient?.id}`)
                      }
                    >
                      Client: {booking?.bookingClient?.given_name}{' '}
                      {booking?.bookingClient?.family_name}
                    </p>
                    <p
                      className="ml-4 text-sm font-medium text-blue-900 cursor-pointer hover:text-blue-400"
                      onClick={(e) => {
                        openEditSection('CLIENT', booking.client);
                      }}
                    >
                      Edit
                    </p>
                  </div>
                </div>

                <div className="flex flex-col border-t border-gray-200 px-4 py-4">
                  <div id={'details'}>
                    <dt className="text-sm font-medium text-gray-500">
                      House Info
                    </dt>
                    {booking.client && <p
                      className="ml-4 text-sm font-medium text-blue-900 cursor-pointer hover:text-blue-400"
                      onClick={(e) => {
                        openEditSection('PROPERTY', booking.client);
                      }}
                    >
                      Edit
                    </p>}
                    <div className="flex items-center py-2 ">
                      <dt className="text-base w-2/6">Address</dt>
                      <dd className="ml-2 text-sm w-4/6 text-gray-900">
                        {booking?.bookingProperty?.apartment_number &&
                          `#${booking?.bookingProperty?.apartment_number}`}{' '}
                        {booking?.bookingProperty?.address}
                      </dd>
                    </div>
                    <div className="flex items-center py-2">
                      <dt className="text-base w-2/6">Entrance Info</dt>
                      <dd className="ml-2 text-sm w-4/6 text-gray-900">
                        {booking?.bookingProperty?.entrance_info}
                      </dd>
                    </div>
                    <div className="flex items-center py-2">
                      <dt className="text-base w-2/6">Parking Info</dt>
                      <dd className="ml-2 text-sm w-4/6 text-gray-900">
                        {booking?.bookingProperty?.parking_info}
                      </dd>
                    </div>
                    <div className="flex items-center py-2">
                      <dt className="text-base w-2/6">Size</dt>
                      <dd className="ml-2 text-sm w-4/6 text-gray-900">
                        {booking?.bookingProperty?.size} (size does{' '}
                        {booking?.bookingProperty?.size_includes_basement
                          ? ''
                          : 'not'}{' '}
                        include basement)
                      </dd>
                    </div>
                    <div className="flex items-center py-2">
                      <dt className="text-base w-2/6">Rooms / Bathrooms</dt>
                      <dd className="ml-2 text-sm w-4/6 text-gray-900">
                        {booking?.bookingProperty?.number_of_rooms} /{' '}
                        {booking?.bookingProperty?.number_of_bathrooms}
                      </dd>
                    </div>
                    <div className="flex items-center py-2">
                      <dt className="text-base w-2/6">Other Info</dt>
                      <dd className="ml-2 text-sm w-4/6 text-gray-900">
                        {booking?.bookingProperty?.other_info}
                      </dd>
                    </div>
                    {booking?.started_at && (
                      <>
                        <div className="flex items-center py-4">
                          <dt className="text-base w-2/6">Started At</dt>
                          <dd className="ml-2 text-sm w-4/6 font-bold text-gray-900">
                            {booking?.started_at}
                          </dd>
                        </div>
                        <div className="flex items-center py-4">
                          <dt className="text-base w-40">Stopped At</dt>
                          <dd className="ml-2 text-sm font-bold text-gray-900">
                            {booking?.stopped_at}
                          </dd>
                        </div>
                      </>
                    )}
                    <div className="flex items-center py-2">
                      <dt className="text-base w-2/6">Extras</dt>
                      <div className="w-4/6 flex flex-row">
                        <dd className="ml-2 text-sm w-full text-gray-900">
                          {booking.extras &&
                            booking.extras.length > 0 &&
                            booking.extras.map((e) => <p key={e.id}>{e.name}</p>)}
                        </dd>
                        <p
                          className="ml-4 text-sm font-medium text-blue-500 cursor-pointer hover:text-blue-400"
                          onClick={(e) => {
                            openEditSection('EXTRAS', booking.receipt);
                          }}
                        >
                          Edit
                        </p>
                      </div>
                    </div>

                    <div className="flex items-center py-2">
                      <dt className="text-base w-2/6">
                        Can cleaning time be extended?
                      </dt>
                      <dd className="ml-2 text-lg w-4/6 text-gray-900">
                        {booking?.can_extend_hours ? 'Yes' : 'No'}
                      </dd>
                    </div>

                    <div className="flex items-center py-2">
                      <dt className="text-base w-2/6">
                        Client notified of upcomming booking?
                      </dt>
                      <dd className="ml-2 text-lg w4/6 text-gray-900">
                        {booking?.notified ? 'Yes' : 'No'}
                      </dd>
                    </div>
                  </div>

                  <div id={'receipt'} className={'py-4'}>
                    <div className={'flex flex-row'}>
                      <dt className="text-sm font-medium text-gray-500">
                        Receipt
                      </dt>
                      <p
                        className="ml-4 text-sm font-medium text-blue-500 cursor-pointer hover:text-blue-400"
                        onClick={(e) => {
                          openEditSection('RECEIPT', booking.receipt);
                        }}
                      >
                        Edit
                      </p>
                      <p
                        className="ml-4 text-sm font-medium text-blue-500 cursor-pointer hover:text-blue-400"
                        onClick={(e) => {
                          setViewReceiptHistory(!viewReceiptHistory);
                        }}
                      >
                        {!viewReceiptHistory ? 'View History' : 'Hide History'}
                      </p>
                    </div>
                    {!viewReceiptHistory && <>
                      <dl className="border-t border-gray-200 py-6 space-y-6">
                        <div className="flex items-center justify-between">
                          <dt className="text-sm">Hourly Rate</dt>
                          <dd className="text-sm font-medium text-gray-900">
                            ${booking?.receipt?.hourly_rate}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-sm">Subtotal (Labour + extras)</dt>
                          <dd className="text-sm font-medium text-gray-900">
                            ${booking?.receipt?.subtotal}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-sm">Fee</dt>
                          <dd className="text-sm font-medium text-gray-900">
                            {`$${booking?.receipt?.fee}`}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-sm">Coupon Amount</dt>
                          <dd className="text-sm font-medium text-gray-900">
                            {`$${booking?.receipt?.coupon_covers}`}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-sm">Taxes</dt>
                          <dd className="text-sm font-medium text-gray-900">
                            ${booking?.receipt?.tax}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between border-t border-gray-200">
                          <dt className="text-base font-medium">Total</dt>
                          <dd className="text-base font-medium text-gray-900">
                            {`$${booking?.receipt?.total}`}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between pt-1">
                          <dt className="text-base font-medium">( Cleaner Gets</dt>
                          <dd className="text-base font-medium text-gray-900">
                            -${booking?.receipt?.cleaner_total} )
                          </dd>
                        </div>
                      </dl>
                    </>}

                    {viewReceiptHistory && <>
                      {receiptHistory.map((rh) => {
                          return <div key={rh.id} >
                            <dl className="border-t border-gray-200 py-6 space-y-6">
                              <div className="flex items-center justify-between">
                                <dt className="text-sm">Created</dt>
                                <dd className="text-sm font-medium text-gray-900">
                                  {dayjs.utc(rh.created_at).tz('America/Edmonton').format('YYYY-MMM-DD: hh:mm')}
                                </dd>
                              </div>
                              <div className="flex items-center justify-between">
                                <dt className="text-sm">Author</dt>
                                <dd className="text-sm font-medium text-gray-900">
                                  {rh.author_name || rh.author_id}
                                </dd>
                              </div>
                              <div className="flex items-center justify-between">
                                <dt className="text-sm">Hourly Rate</dt>
                                <dd className="text-sm font-medium text-gray-900">
                                  ${rh.hourly_rate}
                                </dd>
                              </div>
                              <div className="flex items-center justify-between">
                                <dt className="text-sm">Subtotal (Labour + extras)</dt>
                                <dd className="text-sm font-medium text-gray-900">
                                  ${rh.subtotal}
                                </dd>
                              </div>
                              <div className="flex items-center justify-between">
                                <dt className="text-sm">Fee</dt>
                                <dd className="text-sm font-medium text-gray-900">
                                  {`$${rh.fee}`}
                                </dd>
                              </div>
                              <div className="flex items-center justify-between">
                                <dt className="text-sm">Coupon Amount</dt>
                                <dd className="text-sm font-medium text-gray-900">
                                  {`$${rh.coupon_covers}`}
                                </dd>
                              </div>
                              <div className="flex items-center justify-between">
                                <dt className="text-sm">Taxes</dt>
                                <dd className="text-sm font-medium text-gray-900">
                                  ${rh.tax}
                                </dd>
                              </div>
                              <div className="flex items-center justify-between border-t border-gray-200">
                                <dt className="text-base font-medium">Total</dt>
                                <dd className="text-base font-medium text-gray-900">
                                  {`$${rh.total}`}
                                </dd>
                              </div>
                              <div className="flex items-center justify-between pt-1">
                                <dt className="text-base font-medium">( Cleaner Gets</dt>
                                <dd className="text-base font-medium text-gray-900">
                                  -${rh.cleaner_total} )
                                </dd>
                              </div>
                            </dl>
                          </div>;
                        }
                      )}
                    </>
                    }


                  </div>
                </div>
              </div>
            </section>

            <section>
              <div className="bg-white shadow sm:rounded-lg p-4">
                <CW>
                  <div className={'chatBar'}>
                    <div className={'chatList'} style={{ minHeight: '300px' }}>
                      {messagesList &&
                        messagesList.map((m) => {
                          if (m.sender.role === 'cleaner') {
                            return (
                              <div className={'msg-wrapper-theirs'} key={'theirs-' + m.id}>
                                <div
                                  className={
                                    'chat chat-message theirs theirs-cleaner'
                                  }
                                >
                                  {m.content}
                                </div>
                                <p className={'chat-time'}>
                                  {m.sender.name} -{' '}
                                  {dayjs(m.createdAt).format('MMM D hh:mm a')}
                                </p>
                              </div>
                            );
                          } else if (m.sender.role === 'client') {
                            return (
                              <div className={'msg-wrapper-theirs'} key={'theirs2-' + m.id}>
                                <div
                                  className={
                                    'chat chat-message theirs theirs-client'
                                  }
                                >
                                  {m.content}
                                </div>
                                <p className={'chat-time'}>
                                  {m.sender.name} -{' '}
                                  {dayjs(m.createdAt).format('MMM D hh:mm a')}
                                </p>
                              </div>
                            );
                          } else {
                            return (
                              <div className={'msg-wrapper-mine'} key={'mine-' + m.id}>
                                <div className={'chat chat-message mine'}>
                                  {m.content}
                                </div>
                                <p className={'chat-time'}>
                                  ADMIN {m.sender.name} -{' '}
                                  {dayjs(m.createdAt).format('MMM D hh:mm a')}
                                </p>
                              </div>
                            );
                          }
                        })}
                    </div>
                  </div>
                  <ChatInputWrapper>
                    <input
                      type="text"
                      id="msg-input"
                      name={'chatInputMain'}
                      value={newMessage}
                      onChange={(e) => constUpdateTextBoxmsg(e)}
                      placeholder={'your message...'}
                      className={'chat-input'}
                    />
                    <Button
                      title={'send'}
                      onClick={(e) => {
                        e.preventDefault();
                        submitNewMessageToBooking(
                          newMessage,
                          booking.conversation_id
                        );
                      }}
                      isLoading={/*messageLoading*/ false}
                    >
                      Submit
                    </Button>
                  </ChatInputWrapper>
                </CW>
              </div>
            </section>

            {/*<section aria-labelledby="notes-title">*/}
            {/*  <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">*/}
            {/*    <div className="divide-y divide-gray-200">*/}
            {/*      <div className="px-4 py-5 sm:px-6">*/}
            {/*        <h2 id="notes-title" className="text-lg font-medium text-gray-900">*/}
            {/*          Notes*/}
            {/*        </h2>*/}
            {/*      </div>*/}
            {/*      <div className="px-4 py-6 sm:px-6">*/}
            {/*        <ul className="space-y-8">*/}
            {/*          {comments.map((comment) => (*/}
            {/*              <li key={comment.id}>*/}
            {/*                <div className="flex space-x-3">*/}
            {/*                  <div className="flex-shrink-0">*/}
            {/*                    <img*/}
            {/*                        className="h-10 w-10 rounded-full"*/}
            {/*                        src={`https://images.unsplash.com/photo-${comment.imageId}?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80`}*/}
            {/*                        alt=""*/}
            {/*                    />*/}
            {/*                  </div>*/}
            {/*                  <div>*/}
            {/*                    <div className="text-sm">*/}
            {/*                      <a href="#" className="font-medium text-gray-900">*/}
            {/*                        {comment.name}*/}
            {/*                      </a>*/}
            {/*                    </div>*/}
            {/*                    <div className="mt-1 text-sm text-gray-700">*/}
            {/*                      <p>{comment.body}</p>*/}
            {/*                    </div>*/}
            {/*                    <div className="mt-2 text-sm space-x-2">*/}
            {/*                      <span className="text-gray-500 font-medium">{comment.date}</span>{' '}*/}
            {/*                      <span className="text-gray-500 font-medium">&middot;</span>{' '}*/}
            {/*                      <button type="button" className="text-gray-900 font-medium">*/}
            {/*                        Reply*/}
            {/*                      </button>*/}
            {/*                    </div>*/}
            {/*                  </div>*/}
            {/*                </div>*/}
            {/*              </li>*/}
            {/*          ))}*/}
            {/*        </ul>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className="bg-gray-50 px-4 py-6 sm:px-6">*/}
            {/*      <div className="flex space-x-3">*/}
            {/*        <div className="flex-shrink-0">*/}
            {/*          <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt=""/>*/}
            {/*        </div>*/}
            {/*        <div className="min-w-0 flex-1">*/}
            {/*          <form action="#">*/}
            {/*            <div>*/}
            {/*              <label htmlFor="comment" className="sr-only">*/}
            {/*                About*/}
            {/*              </label>*/}
            {/*              <textarea*/}
            {/*                  id="comment"*/}
            {/*                  name="comment"*/}
            {/*                  rows={3}*/}
            {/*                  className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"*/}
            {/*                  placeholder="Add a note"*/}
            {/*                  defaultValue={''}*/}
            {/*              />*/}
            {/*            </div>*/}
            {/*            <div className="mt-3 flex items-center justify-between">*/}
            {/*              <a*/}
            {/*                  href="#"*/}
            {/*                  className="group inline-flex items-start text-sm space-x-2 text-gray-500 hover:text-gray-900"*/}
            {/*              >*/}
            {/*                <QuestionMarkCircleIcon*/}
            {/*                    className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"*/}
            {/*                    aria-hidden="true"*/}
            {/*                />*/}
            {/*                <span>Some HTML is okay.</span>*/}
            {/*              </a>*/}
            {/*              <button*/}
            {/*                  type="submit"*/}
            {/*                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"*/}
            {/*              >*/}
            {/*                Comment*/}
            {/*              </button>*/}
            {/*            </div>*/}
            {/*          </form>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</section>*/}
            {booking && (
              <Comments
                comments={notes}
                onSubmit={(newNote) => {
                  post(`/bookings/${id}/notes`, { note: newNote })
                    .then((b) => {
                      if (b.status !== 201) {
                        throw Error('could not add note');
                      } else {
                        toast.success('saved new note');
                        window.location.reload();
                      }
                    })
                    .catch((e) => toast.error(e));
                }}
              />
            )}
          </div>

          {/*<section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">*/}
          {/*  <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">*/}
          {/*    <h2 id="timeline-title" className="text-lg font-medium text-gray-900">*/}
          {/*      Timeline*/}
          {/*    </h2>*/}

          {/*    /!* Activity Feed *!/*/}
          {/*    <div className="mt-6 flow-root">*/}
          {/*      <ul className="-mb-8">*/}

          {/*        {bookings.map((booking, itemIdx) => (*/}
          {/*            <li key={booking.id}>*/}
          {/*              <div className="relative pb-8">*/}
          {/*                <div className="relative flex space-x-3">*/}
          {/*                  <div>{getStatus(booking.status)}</div>*/}
          {/*                  <div onClick={() => history.push(`/bookings/${booking.id}`)} className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4 hover:bg-gray-100 cursor-pointer">*/}
          {/*                    <div className={""}>*/}
          {/*                      <p className="text-sm text-grey-900">*/}
          {/*                        With {booking.clientName}{' '} {dayjs(booking.date).format("MMM-DD")}*/}
          {/*                      </p>*/}
          {/*                    </div>*/}
          {/*                    <div className="text-right text-sm whitespace-nowrap text-gray-500">*/}
          {/*                      <p>{booking.status}</p>*/}
          {/*                    </div>*/}
          {/*                  </div>*/}
          {/*                </div>*/}
          {/*              </div>*/}
          {/*            </li>*/}
          {/*        ))}*/}
          {/*      </ul>*/}
          {/*    </div>*/}
          {/*    <div className="mt-6 flex flex-col justify-stretch">*/}
          {/*      <button*/}
          {/*          type="button"*/}
          {/*          className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"*/}
          {/*      >*/}
          {/*        Load More*/}
          {/*      </button>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</section>*/}
        </div>
      </main>
    </div>
  );
}
