import React, { useState, useEffect } from 'react';
import { ChevronDownIcon, CheckCircleIcon } from "@heroicons/react/solid";
import { useHistory, useLocation } from 'react-router-dom';
import { get, post } from '../../helper/fetch';
import {
  ListingAvailability
} from '../../components/StackedList/StackedList';
import { toast } from "react-toastify";

type SearchParams = {
  address: string;
  date: string;
  region?: string | null;
  onOfferSent?: () => void;
};

const statuses = {
  accepted: 'text-green-700 bg-green-50 ring-green-600/20',
  paid: 'text-gray-600 bg-gray-50 ring-gray-500/10',
  rejected: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
  cancelled: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
  canceled: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20'
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function AvailabilitySearcher(props: {
  date: string;
  address: string;
  bookingId: string | number; // Add this prop
  onOfferSent?: () => void;
}) {

  const history = useHistory();
  let location = useLocation();
  const [searchParams, setSearchParams] = useState<{
    date: string;
    address: string;
  }>(null);
  const [listingsAvailability, setListingsAvailability] = useState(
    [] as ListingAvailability[]
  );
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const [selectedListings, setSelectedListings] = useState<string[]>([]);
  const [isOfferLoading, setIsOfferLoading] = useState(false);

  // ... existing useEffect and other logic ...

  const toggleListingSelection = (cleanerId: string) => {
    setSelectedListings((prev) =>
      prev.includes(cleanerId)
        ? prev.filter((id) => id !== cleanerId)
        : [...prev, cleanerId]
    );
  };

  useEffect(() => {
    const initialSearch: SearchParams = {
      address: props.address,
      date: props.date,
    };
    searchForCleaners(initialSearch);
  }, []); // Empty dependency array ensures this runs only once on mount

  async function searchForCleaners(searchData: { address: string, date: string, region?: string | null }) {
    setLoading(true);
    setSearchParams(searchData);
    let urlParams = null;
    if (searchData) {
      urlParams = new URLSearchParams({
        address: searchData.address,
        date: searchData.date,
        // region: searchData.region,
        pageNumber: (0).toString()
      });
    }

    urlParams && history.push({ search: urlParams.toString() });
    get('/cleanerlistings/availability?' + urlParams)
      .then((bs) => {
        if (!bs) throw new Error('result empty');
        setListingsAvailability(bs);
      })
      .catch((e) => {
        setError('could not fetch coupons');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleOffer = async (): Promise<void> => {
    if (selectedListings.length === 0) {
      toast.warning("Please select at least one listing.");
      return;
    }

    setIsOfferLoading(true);
    try {
      const response = await post("/cleanerlistings/offers", {
        bookingId: props.bookingId,
        listingIds: selectedListings,
      });

      if (response.ok) {
        toast.success("Offer sent successfully!");
        setSelectedListings([]); // Clear selections after successful offer
        props.onOfferSent && props.onOfferSent();
      } else {
        throw new Error("Failed to send offer");
      }
    } catch (error) {
      console.error("Error sending offer:", error);
      toast.error("Failed to send offer. Please try again.");
    } finally {
      setIsOfferLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4">
        <div className="flex items-center justify-between">Address</div>
        <label htmlFor="city-select" className="block text-sm font-medium text-gray-700 mb-2">
          {props.address}
        </label>
      </div>

      <div className="mb-4">
        <div className="flex items-center justify-between">Date</div>
        <label htmlFor="city-select" className="block text-sm font-medium text-gray-700 mb-2">
          {props.date}
        </label>
      </div>

      <div className="mt-4 flex justify-end mb-4">
        <button
          className="bg-amber-600 hover:bg-amber-800 text-white font-bold py-2 px-4 rounded"
          onClick={handleOffer}
          disabled={isOfferLoading || selectedListings.length === 0}
        >
          {isOfferLoading ? "Sending Offer..." : "Send Offer"}
        </button>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <div className="max-h-[70vh] overflow-y-auto p-2">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {listingsAvailability.map((listingAvailability) => (
              <div
                key={listingAvailability.id}
                className={`min-w-0  shadow-md rounded-lg mb-4 p-4 border-gray-300 border hover:border-gray-400
                 ${selectedListings.includes(listingAvailability.id) ?
                    "ring-2 ring-hsgreen" : ""}`}
                onClick={() => toggleListingSelection(listingAvailability.id)}
              >
                {selectedListings.includes(listingAvailability.id) && (
                  <CheckCircleIcon className="absolute top-2 right-2 h-6 w-6 text-blue-500" />
                )}
                <div className="flex items-start">
                  <p
                    className="text-md font-semibold text-gray-900 hover:cursor-pointer hover:text-hsgreen"
                    onClick={() => {
                      window.open(`/cleaners/${listingAvailability.id}`);
                    }}
                  >
                    {listingAvailability.cleaner.title}
                  </p>
                </div>
                <div className="flex items-start">
                  <p
                    className={classNames(
                      'text-gray-900 text-md',
                      ' rounded-md mt-0.5 py-0.5 font-medium'
                    )}
                  >
                    Availability: {listingAvailability.time}
                  </p>
                </div>
                <div className="mt-1 flex flex-col space-y-2 text-xs leading-5 text-gray-500">
                  {listingAvailability.bookings.map((booking) => (
                    <p
                      key={booking.id}
                      className={classNames(
                        statuses[booking.status],
                        'rounded-md mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset hover:cursor-pointer w-full overflow-hidden text-ellipsis'
                      )}
                      onClick={() => {
                        window.open(`/bookings/${booking.id}`);
                      }}
                    >
                      <span className="block truncate">
                        #{booking.id} - {booking.time} ({booking.status})
                      </span>
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}